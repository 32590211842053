export const incidentRiderInjuryComplications = [
    <option value={null}>No implications</option>,
    <option value="DNF">Did not finish</option>,
    <option value="DSQ">Disqualified</option>
]

export const incidentRiderInjuryCard = [
    <option value={null}>NONE</option>,
    <option value="YELLOW">Yellow card</option>
]

export const incidentMediaCategories = [
    <option value='IMAGE'>Image</option>,
    <option value='VIDEO'>Video</option>,
    <option value='OTHER'>Other</option>,
]