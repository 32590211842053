import axiosInstance from './axios';

const user_manager = {
    get_current_user: function(){
        return new Promise((resolve, reject)=>{
            axiosInstance.get('/info').then((response)=>{
                resolve(response.data);
            }).catch(()=>{
                resolve(null);
            });   
        })
    },
    logout: function(){
        return new Promise((resolve, reject)=>{
            axiosInstance.delete('/logout').then((response)=>{
                resolve(response.data);
            }).catch(()=>{
                resolve(null);
            }); 
        })
    }
}

export default user_manager;