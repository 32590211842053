import axiosInstance from './axios';

export const loadCauses = () =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/causes').then((resp)=>{
            resolve(resp.data)
        }).catch(()=>{
            resolve([])
        })
    });
}