import axiosInstance from './axios';
import {format_date_short} from '../util/utils';

export const get_race = (id)=>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/stage_details', {params: {'stage_id': id}}).then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        });   
    })
};

export const get_races = () =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/races').then((response) =>{
            resolve(response.data);
        });
    })
};

export const get_last_n_days_races = (days) =>{
    var now = new Date();
    var n_days_ago = new Date();
    n_days_ago.setDate(n_days_ago.getDate()-days);

    return new Promise((resolve, reject)=>{
        axiosInstance.get('/races', {params: {
                                            'date_from': format_date_short(n_days_ago) ,
                                            'date_to': format_date_short(now)
                                            }
        }).then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        });   
    })
}

export const get_participants = (id) =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/race_participants', {params: {'race_stage_id': id}}).then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        });   
    })
}

export const get_stage_incidents = (id) =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/stage_incidents', {params: {'race_stage_id': id}}).then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve([]);
        });
    })
}

export const get_race_incidents = (id) => {
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/race_incidents?race_id='+id).then((response)=>{
            resolve(response.data)
        }).catch(()=>{
            resolve([])
        })
    })
}