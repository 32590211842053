import { Delete, EditOutlined, Save } from "@material-ui/icons";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { hasPrivileges } from "../util/auth";

export const Cause = (props) => {
    const cause_id = props.cause.cause_id;
    const cause_description = props.cause.cause_description;
    const [incidentCauseDescription, setIncidentCauseDescription] = useState(props.cause.incident_cause_description);
    const [editable, setEditable] = useState(false);

    const toggleEditable = ()=>{
        var curVal = editable;
        setEditable(!curVal);
    }

    return (
        <tr className='incidentCause' key={`rider_incident_${cause_id}`}>
            <td className='causeDescription' key={`cause_description${cause_id}`}>{cause_description}</td>
            {hasPrivileges('commissaire')?
                <td className='incidentCauseDescription' key={`incident_cause_description${cause_id}`}>
                    <div className='incidentCauseDescriptionContent'>
                        {!editable?<span onClick={toggleEditable}>{incidentCauseDescription}</span>:<textarea className='form-control' value={incidentCauseDescription} onChange={evt=>{setIncidentCauseDescription(evt.target.value)}}/>}</div>
                    <div className='incidentCauseDescriptionActions'>
                        {!editable?<Button className='btn btn-warning' onClick={toggleEditable}><EditOutlined/></Button>:""}
                        {editable?<Button className='btn btn-success' onClick={()=>{props.updateCause(cause_id, incidentCauseDescription);toggleEditable()}}><Save/></Button>:""}
                        <Button className='btn btn-danger' onClick={()=>props.onDelete(cause_id)}><Delete/></Button>
                    </div>
                </td>:
                <td className='incidentCauseDescription' key={`incident_cause_description${cause_id}`}>
                    {incidentCauseDescription}
                </td>
            }
        </tr>
    );
}
