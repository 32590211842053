import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import {get_races} from '../api/race';
import MaterialTable from 'material-table';
import { hasPrivileges } from '../util/auth';

export default function Races(){
    const allowed_filters = [null, 'ME', 'WE']

    var [races, setRaces] = useState(null);
    var [filter, setFilter] = useState(null);

    var [columns, setColumns] = useState(
        [
            { title: 'Race date', field: 'racedate', defaultSort: 'desc', customSort: (a,b) => Date.parse(a.racedate)-Date.parse(b.racedate)},
            { title: 'Race name', field: 'racename'},
            { title: 'UCI cat.', field: 'uci_category', hidden: false, defaultFilter: filter},
            { title: 'Race country', field: 'racecountry'},
            { title: 'Race category', field: 'racecategory'},
            { title: 'Actions', field: 'actions'}
        ]);
    
    useEffect(()=>{
            setColumns([
                { title: 'Race date', field: 'racedate', defaultSort: 'desc', customSort: (a,b) => Date.parse(a.racedate)-Date.parse(b.racedate)},
                { title: 'Race name', field: 'racename'},
                { title: 'UCI cat.', field: 'uci_category', hidden: true, defaultFilter: filter},
                { title: 'Race country', field: 'racecountry'},
                { title: 'Race category', field: 'racecategory'},
                { title: 'Actions', field: 'actions'}
            ])
    }, [filter])

    useEffect(()=>{
        get_races().then((data)=>{
            var local_races = []
            data.map(race=>{
                var race_date_obj = new Date(race.end_datetime)
                var race_date_str = race_date_obj.toDateString()
                local_races.push({
                    racedate: race_date_str,
                    uci_category: race.race_edition.uci_category,
                    racecategory: String(race.race_edition.uci_category + "/" + race.race_edition.uci_class),
                    racecountry: race.race_edition.race.country.country_iso,
                    racename: race.race_edition.race.name,
                    actions: <div><Link className='btn btn-theme' to={`/race/${race.race_stage_id}`}>Details</Link>{hasPrivileges("commissaire")?<Link className='btn btn-warning' style={{marginLeft: '2px'}} to={`/race/${race.race_stage_id}/create_incident`}>New incident</Link>:""}</div>
                })
            });
            setRaces(local_races);
        }).catch(()=>{
            console.log("Loading the races went wrong!");
        })
    }, []);
    
    if(races){
        return (
            <div key='race-container'>     
                <h1>Races</h1>
                <div className="btn-group">
                    {
                        allowed_filters.map((allowed_filter)=>
                            <button key={`choice-${allowed_filter}`} type="button" onClick={evt=>{setFilter(allowed_filter);console.log(filter);}} className={`btn btn-filter ${filter===allowed_filter?'active':''}`}>{allowed_filter?allowed_filter:'No filter'}</button>
                        )
                    }
                </div> 
                <MaterialTable
                title="Races"
                columns={columns}
                data={races}
                options={{filtering: true, pageSize: 20, sorting: true}} />
            </div>
        )  
    }
    else{
        return (<div>
                    <h1>Races</h1>
                    Loading races...
            </div>)
    }
}
