import axiosInstance from './axios';

export const incidentCauses = (incidentId) =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/incident_causes', {'incident_id': incidentId}).then((resp)=>{
            resolve(resp.data)
        }).catch(()=>{
            resolve([])
        })
    });
}

export const removeIncidentCause = (incidentId, causeId) =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.post('/delete_incident_cause', {'incident_id': incidentId, 'cause_id': causeId}).then((resp)=>{
            resolve(resp.data)
        }).catch(()=>{
            resolve([])
        })
    });
}

export const updateIncidentCauseDescription = (incidentId, causeId, description) => {
    return new Promise((resolve, reject)=>{
        axiosInstance.put('/update_incident_cause', {'incident_id': incidentId, 'cause_id': causeId, 'cause_description': description}).then((resp)=>{
            resolve(resp.data)
        }).catch(()=>{
            resolve([])
        })
    });
}