import MaterialTable, { MTableBodyRow } from 'material-table';
import React, { useState, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useHistory} from 'react-router-dom';

export default function TeamIncidents(props){
    const history = useHistory()
    var [incidents, setIncidents] = useState(null);
    var [yearIndex, setYearIndex] = useState(1);
    let columns = [
        { title: 'Incident ID', field: 'incident_id', hidden: true},
        { title: 'Race name', field: 'race_name'},
        { title: 'Incident time', render: rowData => new Date(rowData.incident_timestamp).toLocaleDateString(), defaultSort: 'desc', customSort: (a,b) => Date.parse(a.incident_timestamp)-Date.parse(b.incident_timestamp)},
        { title: 'Severity (0-10)', field: 'incident_severity'},
        { title: '# involved riders', field: 'involved_riders'},
        { title: '# causes', field: 'incident_causes'},
    ];

    useEffect(()=>{
        if(props.incidents){
            setIncidents(sortBySeason(props.incidents));
        }
    }, [props.incidents]);

    useEffect(()=>{
        if(incidents)
            setYearIndex(Object.entries(incidents).length-1);
    }, [incidents]);

    const sortBySeason = (incidents) => {
        var incidentsBySeason = {}
        for(const incident of incidents){
            var incidentDate = new Date(Date.parse(incident.incident_timestamp));
            console.log(incidentDate);
            var incidentYear = incidentDate.getFullYear();

            if(!(incidentYear in incidentsBySeason)){
                incidentsBySeason[incidentYear]=[];
            }

            incidentsBySeason[incidentYear].push(incident);
        }
        return incidentsBySeason;
    };

    const loadIncident = (evt, rowData)=>{
        //window.location.href=`${process.env.PUBLIC_URL}/#/race/${rowData.race_stage_id}`
        history.push({pathname:`/incident/${rowData.incident_id}`, state: {teamMode: true}})
    }

    if(incidents){
        return (
                <Tabs key='tab-incidents' selectedIndex={yearIndex} onSelect={index=>setYearIndex(index)}>
                            <TabList key='tabs-incidents'>
                                {Object.entries(incidents).map(entry=>{
                                    return <Tab key={`incidents-${entry[0]}`}>{entry[0]}</Tab>
                                })}
                            </TabList>
                            {Object.entries(incidents).map(entry=>{
                                return (
                                        <TabPanel key={`tab-panel-incident-${entry[0]}`}>
                                            <MaterialTable
                                            key={`tab-panel-incident-table-${entry[0]}`}
                                            title="Incidents"
                                            columns={columns}
                                            onRowClick={loadIncident}
                                            data={entry[1]}
                                            options={{filtering: true, sorting: true, tableLayout: 'auto', showTitle:false, search: false, toolbar: false, paging: false}} 
                                            />
                                        </TabPanel>
                                )
                            })}
                </Tabs>
        )
    }
    else
        return <div>Loading...</div>
}