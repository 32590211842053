import React, { useState, useEffect } from 'react'

export const IncidentCreatorStepOne = (props)=>{
    if(props.currentStep!==1){
        return null
    }

    return(
        <div className='subFormPart'>
            <h4>Incident information</h4>
            <div className='form-group'>
                <label htmlFor="incident_description">Description of the incident</label>
                <textarea placeholder='Incident description' id='incident_description'
                                    className='form-control' 
                                    value={props.incident_description} name='incident_description' 
                                    onChange={props.handleChange}/>
            </div>
			<div className='form-group'>
                <label htmlFor="incident_summary">OpenAI Summary of the incident</label>
                <textarea placeholder='Incident summary' id='incident_summary'
                                    className='form-control' 
                                    value={props.incident_summary} name='incident_summary' 
                                    onChange={props.handleChange}/>
            </div>
            <div className="form-group row">
                <div className="col-sm-6">
                    <label htmlFor="incident_severity">Severity of the incident (0-10) <span className="badge badge-info">{props.incident_severity}</span></label>
                    <input placeholder='Incident severity' id='incident_severity'
                                            name="incident_severity"
                                            value={props.incident_severity} 
                                            step='1' 
                                            onChange={props.handleIntChange}
                                            className='custom-range' type='range'
                                            min='0' max='10'/>
                </div>
                <div className="col-sm-6">
                    <label htmlFor="incident_number_of_riders">Number of involved riders (0-50) <span className="badge badge-info">{props.incident_number_of_riders}</span></label>
                    <input placeholder='Incident numberRiders' id='incident_number_of_riders'
                                            name="incident_number_of_riders"
                                            value={props.incident_number_of_riders} 
                                            step='1' 
                                            onChange={props.handleIntChange}
                                            className='custom-range' type='range'
                                            min='0' max='50'/>	
                </div>
            </div>
            <div className='form-group row'>
				<div className="col-sm-6">
					<label htmlFor="incident_km_from_finish">Distance from the finishline (km)</label>
						<input placeholder='Incident km from finish' id='incident_km_from_finish'
                                        className='form-control' type='number' 
                                        value={props.incident_km_from_finish} name='incident_km_from_finish' 
                                        step="0.001" 
                                        onChange={props.handleDoubleChange}/>
				</div>
                <div className="col-sm-6">
					<div className="row">
					<div className="col">
                    <label htmlFor="incident_location_latitude">Location latitude</label>
                    <input placeholder='Location latitude' id='incident_location_latitude'
                                        className='form-control' type='number'
                                        value={props.incident_location_latitude} name='incident_location_latitude' 
                                        step='any' 
                                        onChange={props.handleDoubleChange}/>	
					</div>
					<div className="col">
					<label htmlFor="incident_location_longitude">Location longitude</label>
                    <input placeholder='Location longitude' id='incident_location_longitude'
                                        className='form-control' type='number'
                                        value={props.incident_location_longitude} name='incident_location_longitude' 
                                        step='any' 
                                        onChange={props.handleDoubleChange}/>
					</div>
					</div>
				</div>
            </div>
            <div className='form-group row'>
				<div className="col-sm-6">
				<div className="row">
					<div className="col">
                <label htmlFor="incident_timestamp">Date/time of the incident</label>
                <div className='row'>
                    <div className='col-sm-6'>
                        <input className='form-control' type='date' value={props.incident_timestamp?props.incident_timestamp.toJSON().slice(0,10):null} onChange={props.handleDateChange} name='incident_timestamp'/>
                    </div>
                    <div className='col-sm-6'>
                        <input className='form-control' type='time' value={props.incident_timestamp.toJSON().slice(11,19)} onChange={props.handleTimeChange} name='incident_timestamp'/>
                    </div>
                </div>
				</div>
				<div className="col">
				<label htmlFor="incident_type">Incident type {props.incident_type}</label>
				<select name='incident_type' id='incident_type'                                            
                                            value={props.incident_type} 
                                            onChange={props.handleChange}
                                            className='custom-select'
                    >
                        <option value='CRASH'>CRASH</option>  
						<option value='NO CRASH'>NO CRASH</option>                                                       
                    </select>
				</div>
				</div>
				</div>
                <div className="col-sm-6">
				    <label htmlFor="incident_audience_quantity">Amount of spectators nearby (0-5) <span className="badge badge-info">{props.incident_audience_quantity}</span></label>
                    <input placeholder='Amount of spectators' id='incident_audience_quantity'
                                        name="incident_audience_quantity"
                                        value={props.incident_audience_quantity} 
                                        step='1' 
                                        onChange={props.handleIntChange}
                                        className='custom-range' type='range'
                                        min='0' max='5'/>
				</div>
            </div>
            <div className="form-group row">
                <div className="col-sm-6">
                    <label htmlFor="incident_environment_type">Environment in which accident occured</label>
                    <select name='incident_environment_type' id='incident_environment_type'
                                                                value={props.incident_environment_type} 
                                                                onChange={props.handleChange}
                                                                className='custom-select'
                    >
                        <option value='UNKNOWN'>Unknown</option>
                        <option value='RURAL'>Rural</option>
                        <option value='URBAN'>Urban</option>
                    </select>
                </div>
                <div className="col-sm-6">
                    <label htmlFor="incident_road_type">Road type where accident occured</label>
                    <select placeholder='Incident road type' name='incident_road_type' id='incident_road_type'
                                            name="incident_road_type"
                                            value={props.incident_road_type} 
                                            onChange={props.handleChange}
                                            className='custom-select'
                    >
                        <option value='UNKNOWN'>Unknown</option>
                        <option value='ASPHALT'>Asphalt</option>
                        <option value='CONCRETE'>Concrete</option>
                        <option value='GRAVEL'>Gravel</option>
                        <option value='COBBLESTONES'>Cobblestones</option>
                        <option value='BRICKSTONES'>Brick stones</option>                    
                    </select>
                </div>
            </div>
        </div>
    )
}