import {useParams} from 'react-router';
import {RaceIncidentForm} from '../form_components/race_incident_form'

export default function EditIncident(){
    let {incident_id} = useParams()
    console.log(incident_id);
    return (<RaceIncidentForm 
                race_id="" 
                incident_id={parseInt(incident_id)}
                formState='edit'/>
    )
}