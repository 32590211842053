import {useParams} from 'react-router';
import {RaceIncidentForm} from '../form_components/race_incident_form'

export default function CreateIncident(){
    let {race_id} = useParams()

    return (<RaceIncidentForm 
                race_id={race_id} 
                incident_id="" 
                formState='create'/>
    )
}