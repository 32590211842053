import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom';
import {get_race, get_race_incidents, get_stage_incidents} from '../api/race';
import RaceIncident from './race_incident';
import MaterialTable from 'material-table';

import LinearScaleIcon from '@material-ui/icons/LinearScale';
import TodayIcon from '@material-ui/icons/Today';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';

import { simpleHTMLSearch } from '../util/utils';
import { hasPrivileges } from '../util/auth';

export default function Race(){
    let {race_id} = useParams();
    const [race, setRace] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [participantState, setParticipantState] = useState(false);
    const [raceIncidents, setRaceIncidents] = useState([])
    const [message, setMessage] = useState("")

    var [columns, setColumns] = useState(
        [
            { title: 'Bib', field: 'riderbib', defaultSort: 'asc'},
            { title: 'Name', field: 'ridername'},
            { title: 'Country', field: 'ridercountry'},
            { title: 'Teamcode', field: 'riderteamcode'},
            { title: 'Result', field: 'riderresult'},
        ]);
    
    const setMessageTimeout = (msg)=>{
        setMessage(msg)
        setTimeout(()=>{
            setMessage("")
        }, 5000)
    }
    useEffect(()=>{
        get_race(race_id).then((raceData)=>{
            setRace(raceData)
            var local_participants = []
            raceData.riders.map((rider)=>{
                local_participants.push({
                    riderbib: rider.bib_number,
                    ridername: rider.first_name + " " + rider.last_name,
                    ridercountry: rider.country.country_iso,
                    riderteamcode: rider.resolved_team.team_code,
                    riderresult: Math.trunc(rider.rider_result)
                })
            })
            setParticipants(local_participants)
        });
    }, []);

    useEffect(()=>{
        get_race_incidents(race_id).then((incidents)=>{
            setRaceIncidents(incidents)
        })
    }, [])

    if(race){
        return (
            <div>
                <h1>{race.race_edition.race.name}</h1>
                {message}
                <h3>Race details</h3>
                <ul className="raceDetails">
                    <li><DirectionsBikeIcon/> {race.race_edition.race.name} - {race.race_edition.uci_category} - {race.race_edition.uci_class}</li>
                    <li><TodayIcon/> {new Date(race.end_datetime).toDateString()}</li>
                    <li><LinearScaleIcon/> {race.stage_index>0?`Stage ${race.stage_index}`:'one day race'}</li>
                    <li><TwitterIcon/> {race.race_edition.race.twitter?(`@${race.race_edition.race.twitter}`):(`No Twitter`)}</li>
                    <li><LanguageIcon/> {race.race_edition.race.organisation_website?(<a href={race.race_edition.race.organisation_website.startsWith('http')?race.race_edition.race.organisation_website:'https://'+race.race_edition.race.organisation_website} target='blank'>{race.race_edition.race.organisation_website}</a>):("no website")}</li>
                    <li><EmailIcon/> {race.race_edition.race.organisation_email?(<a href={`mailto:${race.race_edition.race.organisation_email}`}>{race.race_edition.race.organisation_email}</a>):("No email provided")}</li>
                </ul>
                <div className='incidents-overview'>
                    <h3>Reported incidents
                    {hasPrivileges("commissaire")?<div className='btn btn-success float-right'><Link to={{
                        pathname:`/race/${race_id}/create_incident`,
                        props: {
                            race_obj: {
                                'race_name': race.race_edition.race.name, 
                                'race_date': new Date(race.start_datetime)
                            }
                        }}}>Create new incident</Link></div>:""}
                    </h3>
                    {raceIncidents.length==0?
                    <p>No race incidents available...</p>:
                    <div className='race-incidents-overview'>
                        <input type="text" id="race-incidents-searchbar" onKeyUp={()=>{simpleHTMLSearch("race-incidents-searchbar", "race-incidents-table")}} placeholder="Filter ..."></input>
                        <table className='table table-striped' id="race-incidents-table">
                            <thead>
                                <tr>
                                    <th>Causes</th>
                                    <th>Riders</th>
                                    <th>Dist. from finish (km)</th>
                                    <th>Incident timestamp</th>
                                    <th>Incident last edited</th>
                                    <th>Incident source</th>
                                    {hasPrivileges("commissaire")?<th>Actions</th>:""}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(raceIncidents).map(raceIncident=>{
                                    return <RaceIncident incident={raceIncident} setMessage={setMessageTimeout}/>
                                })}
                            </tbody>
                        </table>
                    </div>
                    }
                </div>
                <h3>Participants
                    <div className='toggle-bar'>
                        {participantState===false?
                        <span className='toggle-item hide-panel' onClick={()=>{setParticipantState(true)}}>Show</span>:
                        <span className='toggle-item show-panel' onClick={()=>{setParticipantState(false)}}>Hide</span>
                        }
                    </div>
                </h3>
                <div className='hideable' id="participants-table" style={{display: participantState?"block":"none"}}>
                    <MaterialTable
                                title="Participants"
                                columns={columns}
                                data={participants}
                                options={{filtering: true, paging: false, sorting: true}} />
                </div>
            </div>
        )
    }
    else{
        return (
            <div>
                <h1>Race</h1>
                <p>Loading...</p>
            </div>

        )
    }

}