import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import {loadCauses} from '../api/causes';

export const IncidentCreatorStepThree = (props)=>{
    //Load the causes possibilities
    let [causes, setCauses] = useState([])
    let [selectedCauseOption, setSelectedCauseOption] = useState({"cause_id": "", "cause_description": ""})
    let [selectedCauses, setSelectedCauses] = useState([])

    useEffect(()=>{
        loadCauses().then((resp)=>{
            var options = []
            resp.map((cause)=>{
                options.push({
                    value: cause['cause_id'],
                    label: cause['cause_description']
                })
            })
            setCauses(options)
        })
    }, [])

    useEffect(()=>{
        setSelectedCauses(props.selectedCauses)
    }, [props.selectedCauses])

    if(props.currentStep!==3){
        return null
    }

    var causeDropdownChanged = option =>{
        const {label, value}=option
        setSelectedCauseOption({...selectedCauseOption, cause_id: value, cause_description: label})
    }

    var incidentCauseDescriptionChanged = evt =>{
        const {label, value} = evt.target;
        setSelectedCauseOption({...selectedCauseOption, incident_cause_description: value})

    }

    var addNewCause = ()=>{
        if(selectedCauseOption.cause_id!==""){
            props.causeCallback(selectedCauseOption)
            setSelectedCauseOption({
                "cause_id": "",
                "cause_description": "",
                "incident_cause_description": ""
            })
        }
        else{
            alert("A valid cause should be provided.")
        }
    }

    return(
        <div className='subFormPart'>
            <h4>Incident causes</h4>
            <div className='selection-area' id="select-causes">
                <div className="form-group">
                    <label htmlFor="cause-dropdown">Select a cause (you can add multiple to this incident)</label>
                    <Select name="cause-dropdown" options={causes} defaultValue={selectedCauseOption.cause_id} onChange={causeDropdownChanged}/>
                </div>
                <div className="form-group">
                    <label htmlFor="incident-cause-description">Extra description about cause</label>
                    <textarea type="text" className="form-control" value={selectedCauseOption.incident_cause_description} onChange={incidentCauseDescriptionChanged} name="incident-cause-description"/>
                </div>
                <div className='form-group'>
                    <a className='btn btn-secondary' onClick={addNewCause}>Add cause</a>
                </div>
            </div> 
            <div id="selected-incident-causes" className='selected-area'>
                <h5>Selected causes</h5>
                <div id="selected-causes">
                    <table>
                        <tr>
                            <th>Cause</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                        {selectedCauses.map((selectedCause)=>{
                            return (
                                <tr className='selectedCauseEntry' key={selectedCause.cause_id}>
                                    <td>{selectedCause.cause_description}</td>
                                    <td>{selectedCause.incident_cause_description}</td>
                                    <td><a className="btn btn-danger" onClick={()=>{props.removeCauseCallback(selectedCause.cause_id)}}>Delete</a></td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
        </div>
    )
}