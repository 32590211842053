import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import incident_manager from '../api/incident';
import {loadCauses} from '../api/causes';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import MaterialTable, {MTableBodyRow} from 'material-table';
import { hasExactPrivilege, hasPrivileges } from '../util/auth';

export default function Incidents(props){
    var [incidents, setIncidents] = useState(null);
    var [message, setMessage] = useState(null);
    var [teamMode, setTeamMode] = useState(props&&props.teamMode?props.teamMode:false);

    let columns = [
            { title: 'Incident ID', field: 'incident_id'},
            { title: 'Race name', field: 'racename'},
            { title: 'Incident time', field: 'incident_ts', defaultSort: 'desc', customSort: (a,b) => Date.parse(a.incident_ts)-Date.parse(b.incident_ts)},
            { title: 'Severity (0-10)', field: 'incident_severity'},
            { title: '# involved riders', field: 'incident_involved_riders'},
            { title: 'causes', field: 'causes', hidden: true},
            { title: '# causes', field: 'incident_num_causes'},
            { title: 'Actions', field: 'incident_actions'}
        ];

    var [selectedCauses, setSelectedCauses] = useState([])
    var [causes, setCauses] = useState([])
    
    const confirmDialog = incident_id => {
        confirmAlert({
          title: 'Confirm to submit',
          message: 'Are you sure you want to delete the incident '+incident_id+"?",
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                  incident_manager.remove_incident({'incident_id': incident_id}).then((res)=>{
                      setMessage("Successfully removed incident "+incident_id);
                      teamMode?load_team_incidents():load_incidents();
                  })
              }
            },
            {
              label: 'No',
              onClick: () =>{
                  setMessage("");
              }
            }
          ]
        });
      };
    
    let load_incidents = () => {
        incident_manager.get_incidents().then((response) =>{
            var incident_rows = []
            Object.values(response).forEach((incident)=>{
                incident_rows.push({
                    incident_id: incident['incident_id'],
                    racename: incident["race_name"],
                    incident_ts: incident['incident_timestamp'],
                    incident_severity: incident['incident_severity'],
                    incident_involved_riders: incident["involved_riders"],
                    incident_num_causes: incident["incident_causes"],
                    causes: incident['causes'],
                    incident_actions: <div className='incident-actions'><Link className='btn btn-theme' to={`/incident/${incident['incident_id']}`}>View</Link>{hasPrivileges("commissaire")?<span className='btn btn-danger' onClick={()=>confirmDialog(incident.incident_id)}>Delete</span>:""}</div>,
                })
            })
            setIncidents(incident_rows);
        });
    }

    let load_team_incidents = () => {
        incident_manager.get_team_incidents().then((response) =>{
            var incident_rows = []
            Object.values(response).forEach((incident)=>{
                incident_rows.push({
                    incident_id: incident['incident_id'],
                    racename: incident["race_name"],
                    incident_ts: incident['incident_timestamp'],
                    incident_severity: incident['incident_severity'],
                    incident_involved_riders: incident["involved_riders"],
                    incident_num_causes: incident["incident_causes"],
                    causes: incident['causes'],
                    incident_actions: <div className='incident-actions'><Link className='btn btn-theme' to={`/incident/${incident['incident_id']}`}>View</Link>{hasPrivileges("commissaire")?<span className='btn btn-danger' onClick={()=>confirmDialog(incident.incident_id)}>Delete</span>:""}</div>,
                })
            })
            setIncidents(incident_rows);
        });
    }

    let load_causes = () =>{
        loadCauses().then((response)=>{
            setCauses(response)
        })
    }

    useEffect(()=>{
        teamMode?load_team_incidents():load_incidents();
        load_causes();
    }, [teamMode]);

    useEffect(()=>{
        console.log(selectedCauses);
    }, [selectedCauses])

    let toggleFilter = (filter_value)=>{
        if(!selectedCauses.includes(filter_value)){
            setSelectedCauses([...selectedCauses, filter_value])
        }
        else{
            setSelectedCauses(selectedCauses.filter(item=>item!==filter_value))
        }
    }

    let selectAll = () => {
        if(causes.length===selectedCauses.length)
            setSelectedCauses([])
        else
            setSelectedCauses(causes.map((cause)=>cause.cause_id))
    }

    if(incidents && selectedCauses){
        return (
            <div>
                <h1>Incidents</h1>
                <span>{message}</span>
                <span className='filter-team'>
                    {hasExactPrivilege('staff')?<span class="badge">{teamMode?"You're currently viewing your team's incidents only.":"You're currently viewing all incidents"}</span>:""}
                    {hasExactPrivilege('staff')?<button className={`btn btn-${teamMode?"warning":"success"}`} onClick={()=>setTeamMode(!teamMode)}>{teamMode?"Show all incidents":"Show my team's incidents only"}</button>:""}
                </span>
                <div className='filter-area'>
                    <div className="btn-group btn-filter-group">
                        <button key={`choice-selectall`} 
                                    type="button" 
                                    onClick={evt=>{selectAll();}} 
                                    className={`btn btn-filter ${selectedCauses.length === causes?'active':''}`}>ALL</button> 
                        {
                            causes.map((cause)=>
                                <button key={`choice-${cause.cause_id}`} 
                                        type="button" 
                                        onClick={evt=>{toggleFilter(cause.cause_id);}} 
                                        className={`btn btn-filter ${selectedCauses.includes(cause.cause_id)?'active':''}`}>{cause.cause_description}</button>
                            )
                        }
                    </div> 
                </div>
                <MaterialTable
                title="Incidents"
                columns={columns}
                data={incidents}
                options={{filtering: true, paging: false, pageSize: 20, sorting: true}}
                components={{
                    Row: props=>{
                        if(selectedCauses.length>0){
                            if(props.data.causes.some(cause=>selectedCauses.indexOf(cause)>=0)){
                                return <MTableBodyRow {...props}/>
                            }
                            else{
                                return null
                            }
                        }
                        else{
                            return <MTableBodyRow {...props}/>
                        }

                    },
                    Toolbar: props=>{
                        return null
                    }
                }}
                />
            </div>
        )
    }
    else{
        return (<div><h1>Incidents</h1><p>Loading incidents...</p></div>)
    }
    
}