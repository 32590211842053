import { Add, DeleteForever, LinkTwoTone, Videocam, PictureAsPdf } from '@material-ui/icons';
import React, { useState, useEffect, useCallback} from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';
import incident_manager from '../api/incident'
import { hasPrivileges } from '../util/auth';
import { incidentMediaCategories } from '../util/values';
import AddIncidentMediaPopup from './add_incident_media_popup';

export default function IncidentMedia(props){
    let incidentId = props.incidentId
    let [images, setImages] = useState([])
    let [imageIds, setImageIds] = useState([])
    let [links, setLinks] = useState([])
    let [linkIds, setLinkIds] = useState([])
	let [videoLinks, setVideoLinks] = useState([])
    let [videoLinkIds, setVideoLinkIds] = useState([])
	let [pdfLinks, setPdfLinks] = useState([])
    let [pdfLinkIds, setPdfLinkIds] = useState([])
    let [imageOpened, setImageOpened] = useState(false)
    let [openedImage, setOpenedImage] = useState(0)

    let [popupVisible, setPopupVisible] = useState(false)
    let [selectedType, setSelectedType] = useState("IMAGE")
    let [incidentMediaUrl, setIncidentMediaUrl] = useState(null)

    useEffect(()=>{
        loadMedia();
    }, [incidentId])

    let loadMedia = () =>{
        incident_manager.incident_media(incidentId).then(mediaList=>{
            filterMedia(mediaList)
        }).catch(err=>{
            setImages([])
            setImageIds([])
            setLinks([])
            setLinkIds([])
			setVideoLinks([])
            setVideoLinkIds([])
			setPdfLinks([])
            setPdfLinkIds([])
        })
    }

    let filterMedia = (mediaList)=>{
        let imageExtensions = ['jpg', 'jpeg', 'png']
		let videoExtensions = ['avi', 'mov', 'wmv', 'mp4','mkv']
		let pdfExtensions = ['pdf']
        let filteredImages = []
        let filteredImageIds = []
        let filteredLinks = []
        let filteredLinkIds = []
		let filteredVideoLinks = []
		let filteredVideoLinkIds = []
		let filteredPdfLinks = []
		let filteredPdfLinkIds = []
		
        mediaList.forEach(element => {
            var extension = element.incident_media_url.split('.').pop().toLowerCase()
            if(imageExtensions.includes(extension)){
                filteredImages.push(element.incident_media_url)
                filteredImageIds.push(element.incident_media_id);
            }
            else{
				if(videoExtensions.includes(extension) || element.incident_media_url.toUpperCase().includes("YOUTUBE")){
					filteredVideoLinks.push(element.incident_media_url)
					filteredVideoLinkIds.push(element.incident_media_id)
				}
				else{
					if(pdfExtensions.includes(extension)){						
						filteredPdfLinks.push(element.incident_media_url)
						filteredPdfLinkIds.push(element.incident_media_id)
					}
					else{			
						filteredLinks.push(element.incident_media_url)
						filteredLinkIds.push(element.incident_media_id)
					}
				}
            }
        });
        //console.log(filteredImages);
        //console.log(filteredImageIds);
        setImages(filteredImages)
        setImageIds(filteredImageIds)
		setVideoLinks(filteredVideoLinks)
		setVideoLinkIds(filteredVideoLinkIds)
		setPdfLinks(filteredPdfLinks)
		setPdfLinkIds(filteredPdfLinkIds)
        setLinks(filteredLinks)
        setLinkIds(filteredLinkIds)
    }

    const openImageViewer = useCallback((index) => {
        setOpenedImage(index);
        setImageOpened(true);
    }, []);
    
    const closeImageViewer = () => {
        setOpenedImage(0);
        setImageOpened(false);
    };

    const addMedia = () =>{
        console.log(selectedType)
        incident_manager.add_incident_media(incidentId, selectedType, incidentMediaUrl).then(id=>{
            console.log("Added media ",id);
            setIncidentMediaUrl(null);
            setPopupVisible(false);
            loadMedia();
        })
    }

    const deleteMedia = (id) =>{
        console.log("Removing media id ",id)
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete the media '+id+"?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    incident_manager.remove_incident_media(id).then(resp=>{
                        if(resp!==null){
                            setImageIds([])
                            setImages([])
							setVideoLinks([])
							setVideoLinkIds([])
							setPdfLinks([])
							setPdfLinkIds([])
                            setLinks([])
                            setLinkIds([])
                            loadMedia();
                        }
                    })
                }
              },
              {
                label: 'No',
                onClick: () =>{
                }
              }
            ]
          });
    }

    const hidePopup = () => {
        setPopupVisible(false)
    }

    return (
        <div className='incidentMediaContainer'>
            <h3>Incident media</h3>
            {hasPrivileges("commissaire")?
            <div className='newIncidentMediaContainer'>
                <span className='btn btn-success' onClick={()=>setPopupVisible(true)}><Add/> Add media</span>
                <AddIncidentMediaPopup      selectedType={selectedType} 
                                            changeSelectedType={(evt)=>{console.log(evt);setSelectedType(evt.target.value)}}
                                            selectedIncidentMediaUrl={incidentMediaUrl}
                                            changeSelectedIncidentMediaUrl={(evt)=>{setIncidentMediaUrl(evt.target.value)}}
                                            setUploadedUrl={(val)=>setIncidentMediaUrl(val)}
                                            addMedia={addMedia}
                                            hidePopup={hidePopup}
                                            visible={popupVisible}
                />
            </div>:
            ""}
            <h5>Images</h5>                
            <div className='imgThumbContainer'>
                {images.map((src, index) => (
                    <div className='imgThumb' key={`img-${src}`} style={{cursor: "pointer"}}>
                        <img
                        src={ src }
                        onClick={ () => openImageViewer(index) }
                        width="300"
                        key={ index }
                        style={{ margin: '2px' }}
                        alt=""/>
                        {hasPrivileges('commissaire')?<div className='imageDeleteBtn' onClick={()=>deleteMedia(imageIds[index])}><span className='btn btn-danger'><DeleteForever/></span></div>:""}
                    </div> 
                ))}
            </div>
            {imageOpened && (
                <ImageViewer
                src={ images }
                currentIndex={ openedImage }
                onClose={ closeImageViewer }
            />)}
            <h5>Links</h5>
            <ul className='incidentMediaLinkContainer'>
                {videoLinks.map((src, index)=>{
                    return <li className='incidentMediaLink' key={`link-${index}`}>
                                <a href={src} style={{color: "black"}} target='blank'><Videocam alt={index+1}/></a>
                                {hasPrivileges("commissaire")?<div className='linkDeleteBtn' onClick={()=>deleteMedia(videoLinkIds[index])}>
                                    <span className='btn btn-danger'>
                                        <DeleteForever/>
                                    </span>
                                </div>:""}
                    </li>
                })}				
				{pdfLinks.map((src, index)=>{
                    return <li className='incidentMediaLink' key={`link-${index}`}>
                                <a href={src} style={{color: "black"}} target='blank'><PictureAsPdf alt={index+1}/></a>
                                {hasPrivileges("commissaire")?<div className='linkDeleteBtn' onClick={()=>deleteMedia(pdfLinkIds[index])}>
                                    <span className='btn btn-danger'>
                                        <DeleteForever/>
                                    </span>
                                </div>:""}
                    </li>
                })}	
				{links.map((src, index)=>{
                    return <li className='incidentMediaLink' key={`link-${index}`}>
                                <a href={src} target='blank'><LinkTwoTone alt={index+1}/></a>
                                {hasPrivileges("commissaire")?<div className='linkDeleteBtn' onClick={()=>deleteMedia(linkIds[index])}>
                                    <span className='btn btn-danger'>
                                        <DeleteForever/>
                                    </span>
                                </div>:""}
                    </li>
                })}
            </ul>
        </div>
    )
}