import React, { useState, useEffect } from 'react'
import { get_user_team } from '../api/teams';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import getCountryISO2 from 'country-iso-3-to-2';
import ReactCountryFlag from "react-country-flag"
import RiderTeam from './rider_team';
import TeamIncidents from './team_incidents';
import RiderIncidents from './rider_incidents';

export default function TeamDashboard(){
    let [teamInfo, setTeamInfo] = useState(null);
    let [tabSeasons, setTabSeasons] = useState([]);
    let [tabRiders, setTabRiders] = useState([])
    let [tabIndex, setTabIndex] = useState(0)

    let [overviewVisible, setOverviewVisible] = useState(true)
    let [selectedRider, setSelectedRider] = useState(null)
    let [selectedSeason, setSelectedSeason] = useState(null)
    
    useEffect(()=>{
        get_user_team().then(resp=>{
            setTeamInfo(resp)
            var seasons = [];
            var riders = []
            for(const [key, value] of Object.entries(resp.riders)){
                seasons.push(key);
                riders.push(value);
            }
            setTabSeasons(seasons);
            setTabRiders(riders);
        });
    }, [])


    useEffect(()=>{
        if(tabSeasons.length>0){
            const seasonsNumeric = tabSeasons.map(str => {
                return Number(str);
            });
            setTabIndex(seasonsNumeric.indexOf(Math.max(...seasonsNumeric)));
        }  
    }, [tabSeasons])

    if(teamInfo){
        return (
            <div>
                <h1>Team info {teamInfo.team_name} ({teamInfo.team_code})</h1>
                <h3>General info</h3>
                <div className='teamGeneralInfo'>
                    <img style={{height: '30px', width: 'auto'}} src={`/ucilogos/${teamInfo.uci_category}.png`}/>
                    <span className='teamName'>{teamInfo.country?<ReactCountryFlag svg style={{height: '20px', width: 'auto'}} countryCode={getCountryISO2(teamInfo.country.country_iso)}/>:""} {teamInfo.team_name}</span>
                </div>
                <div className='row overviewPage' style={{'display': overviewVisible?"":"none"}}>
                    <div className='col col-md-6'>
                        <h3>Team roster</h3>
                        <Tabs key='tab-riders' selectedIndex={tabIndex} onSelect={index=>setTabIndex(index)}>
                            <TabList key='tabs-riders'>
                                {tabSeasons.map(value=>{
                                    return <Tab key={`riders-${value}`}>{value}</Tab>
                                })}
                            </TabList>
                            {tabRiders.map((value, i)=>{
                                return <TabPanel><RiderTeam rider={value} year={tabSeasons[i]} team={teamInfo} onClick={(rider_id)=>{setSelectedRider(rider_id);setSelectedSeason(tabSeasons[i]);setOverviewVisible(false);}}/></TabPanel>
                            })}
                        </Tabs>
                    </div>
                    <div className='col col-md-6'>
                        <h3>Team incidents</h3>
                        <TeamIncidents incidents={teamInfo?teamInfo.incidents:null}/>
                    </div>
                </div>
                <div className='riderDetailPane' style={{'display': !overviewVisible?"":"none"}}>
                    <div className='riderIncidentHeader'>
                        <h3>Rider incidents</h3>
                        <button className='btn btn-warning' onClick={()=>setOverviewVisible(true)}>&larr; Back to dashboard</button>
                    </div>
                    <RiderIncidents rider={selectedRider} season={selectedSeason}/>
                </div>
            </div>
        )
    }
    else{
        return (
            <div>
                LOADING...
            </div>
        )
    }
    
}