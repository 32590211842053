import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom';
import {get_participants, get_stage_incidents} from '../api/race';
import incident_manager from '../api/incident';

import { format_date } from '../util/utils'

import Parser from 'html-react-parser';

export default function IncidentCreator(props){
    let {race_id} = useParams();
    let race_obj = props.location.props.race_obj
    let [participantOptions, setParticipantOptions] = useState(null);
    let [riderSeverity, setRiderSeverity] = useState(0);
    let [incidentSeverity, setIncidentSeverity] = useState(0);
    let [raceIncidents, setRaceIncidents] = useState(null);
    let [newIncident, setNewIncident] = useState(true);
    let [incidentQty, setIncidentQty] = useState(0);
    let [message, setMessage] = useState(null);
    let [incidentId, setIncidentId] = useState(null);

    let loadIncidents = useCallback(() =>{
        get_stage_incidents(race_id).then((incidents)=>{
            var options = [];
            incidents.forEach((incident)=>{
                var selected = ""
                if(incidentId === incident.incident_id){
                    selected = "selected"
                }
                options.push(`<option ${selected} value="${incident.incident_id}">Incident on ${incident.incident_km_from_finish} km from finish -  ${incident.incident_timestamp}</option>`)
            })
            setRaceIncidents(options);
        });
    }, [race_id, incidentId])

    useEffect(()=>{
        get_participants(race_id).then((participants)=>{
            var options = []
            participants.foreach((participant)=>{
                options.push(`<option value="${participant.rider.rider_id}">${participant.rider.first_name} ${participant.rider.last_name}</option>`)
            })
            setParticipantOptions(options)
        });
        loadIncidents();
    },[race_id, loadIncidents]);
    
    let sliderRiderChanged = (evt)=>{
        setRiderSeverity(evt.target.value);
    };

    let sliderIncidentChanged = (evt)=>{
        setIncidentSeverity(evt.target.value);
    };

    let incidentChanged = (evt) =>{
        console.log(evt.target.value==="None");
        if(evt.target.value && evt.target.value === "None"){
            setNewIncident(true);
        }
        else{
            setNewIncident(false);
            setIncidentId(evt.target.value);
        }
    }

    let incidentQtyChanged = (evt) =>{
        setIncidentQty(evt.target.value);
    }

    let submitForm = (evt) =>{
        evt.preventDefault();
        var newIncidentData = {}
        if(evt.target.incident_linked_event.value==="None"){
            newIncidentData = gather_incident_data(evt)
            console.log(newIncidentData)
            incident_manager.create_incident(newIncidentData).then((inserted_id)=>{
                setMessage("Incident with id "+inserted_id+" was created.")
                console.log("Incident with id "+inserted_id+" was created.")
                setIncidentId(parseInt(inserted_id))
                loadIncidents()
                let incidentRiderData = gather_incident_rider_data(evt, inserted_id)
                console.log(incidentRiderData)
                add_incident_rider_data(incidentRiderData)
            }).catch((err)=>{
                console.log("Error creating incident")
                console.log(err)
            })
        }
        else{
            let incidentRiderData = gather_incident_rider_data(evt, incidentId)
            console.log(incidentRiderData)
            add_incident_rider_data(incidentRiderData)
        }
    }

    let add_incident_rider_data = (data) => {
        incident_manager.create_incident_rider(data).then((res)=>{
            setMessage(res);

        })
    }

    let new_incident_html = <div>
        <div className='form-group'>
                <label for='incident_severity'>Incident severity</label>
                <input name='incident_severity' type="range" min="0" max="10" value={incidentSeverity} step='1' onChange={sliderIncidentChanged}/>
            </div>
            <div className='form-group'>
                <label for='incident_location_latitude'>Latitude</label>
                <input type='number' step="0.00000001" name='incident_location_latitude'></input>
            </div>
            <div className='form-group'>
                <label for='incident_location_longitude'>Longitude</label>
                <input type='number' step="0.00000001" name='incident_location_longitude'></input>
            </div>
            <div className='form-group'>
                <label form='incident_datetime'>Date and time</label>
                <input type='date' value={race_obj.race_date.toJSON().slice(0,10)} name='incident_datetime_date'></input>
                <input type='time' name='incident_datetime_time'></input>
            </div>
            <div className='form-group'>
                <label for='incident_km_from_finish'>Kilometers from finishline</label>
                <input type='number' step="0.00000001" name='incident_km_from_finish'></input>
            </div>
            <input type='hidden' name='incident_status' value='NEW'/>
            <input type='hidden' name='incident_source' value='WEBFORM'/>
            <input type='hidden' name='incident_race_stage_id' value={race_id}/>	
            <div className='form-group'>
                <label for="incident_road_type">Road type</label>
                <select name='incident_road_type' value={props.incident_road_type}>
                    <option value='UNKNOWN'>Unknown</option>
                    <option value='ASPHALT'>Asphalt</option>
                    <option value='CONCRETE'>Concrete</option>
                    <option value='GRAVEL'>Gravel</option>
                    <option value='COBBLESTONES'>Cobblestones</option>
                    <option value='BRICKSTONES'>Brick stones</option>
                </select>
            </div>
            <div className='form-group'>
                <label for="incident_environment_type">Environment type</label>
                <select name='incident_environment_type'>
                    <option value='UNKNOWN'>Unknown</option>
                    <option value='RURAL'>Rural</option>
                    <option value='URBAN'>Urban</option>
                </select>
            </div>	
            <div className='form-group'>
                <label for='incident_audience_quantity'>Amount of audience nearby</label>
                <input name='incident_audience_quantity' type="range" min="0" max="10" value={incidentQty} step='1' onChange={incidentQtyChanged}/>
            </div>	
        </div>

    let gather_incident_rider_data = (evt, incident_id) => {
        let newRiderIncidentData = {
            "rider_id": evt.target.rider_id.value,
            "incident_id": incident_id,
            "incident_rider_implications": evt.target.incident_rider_implications.value !== "NONE"?evt.target.incident_rider_implications.value:null,
            "incident_rider_injury": evt.target.incident_rider_injury.value.length>0?evt.target.incident_rider_injury.value:null,
            "incident_rider_severity": riderSeverity,
            "incident_rider_notes": evt.target.incident_rider_notes.value.length>0?evt.target.incident_rider_notes.value:null
        }
        if(!newRiderIncidentData.incident_rider_implications)
            delete newRiderIncidentData.incident_rider_implications
        if(!newRiderIncidentData.incident_rider_injury)
            delete newRiderIncidentData.incident_rider_injury
        if(!newRiderIncidentData.incident_rider_notes)
            delete newRiderIncidentData.incident_rider_notes
        return newRiderIncidentData;
    }

    let gather_incident_data = (evt)=>{
        let newIncidentData = {
            "incident_severity": evt.target.incident_severity.value,
            "incident_location_latitude": evt.target.incident_location_latitude.value.length>0?evt.target.incident_location_latitude.value:null,
            "incident_location_longitude": evt.target.incident_location_longitude.value.length>0?evt.target.incident_location_longitude.value:null,
            "incident_timestamp": format_date(new Date(evt.target.incident_datetime_date.value+(evt.target.incident_datetime_time.value.length>0?(' '+evt.target.incident_datetime_time.value):(' 00:00:00')))),
            "incident_km_from_finish": evt.target.incident_km_from_finish.value.length>0?evt.target.incident_km_from_finish.value:null,
            "incident_source":  evt.target.incident_source.value,
            "incident_status":  evt.target.incident_status.value,
            "incident_road_type":  evt.target.incident_road_type.value,
            "incident_environment_type":  evt.target.incident_environment_type.value,
            "incident_incident_audience_quantity": evt.target.incident_audience_quantity.value,
            "incident_race_stage_id": evt.target.incident_race_stage_id.value
        }
        if(!newIncidentData.incident_location_latitude)
            delete newIncidentData.incident_location_latitude
        if(!newIncidentData.incident_location_longitude)
            delete newIncidentData.incident_location_longitude
        if(!newIncidentData.incident_km_from_finish)
            delete newIncidentData.incident_km_from_finish
        return newIncidentData;
    }
    if(participantOptions && raceIncidents){
        return (
            <div>
                <h1>Report incident</h1>
                <form onSubmit={submitForm}>
                    <div className='form-group'> 
                        <label for="race_id">Race: {race_obj.race_name}</label>
                        <input type='number' value={race_id} name='race_id' hidden/>
                    </div>
                    <div className='form-group'>
                        <label for="rider_id">Participant</label>
                        <select defaultValue={race_obj.rider_id} name='rider_id'>
                            {participantOptions.map((option)=>{
                                return Parser(option)
                            })}
                        </select>
                    </div>
                    <div className='form-group'>
                        <label for='incident_rider_implications'>Incident implications</label>
                        <select name='incident_rider_implications'>
                            <option value='NONE'>No implications</option>
                            <option value='DNF'>Did not finish</option>
                        </select>
                    </div>
                    <div className='form-group'>
                        <label for='incident_rider_injury'>Rider injury</label>
                        <input type='text' name='incident_rider_injury'></input>
                    </div>
                    <div className='form-group'>
                        <label for='incident_rider_severity'>Incident injury severity</label>
                        <input name='incident_rider_severity' type="range" min="0" max="10" value={riderSeverity} step='1' onChange={sliderRiderChanged}/>
                    </div>                     
                    <div className='form-group'>
                        <label for='incident_rider_notes'>Incident notes</label>
                        <input type='text' name='incident_rider_notes'></input>
                    </div> 
                    <div className='form-group'>
                        <label for="incident_linked_event">Attach to existing event</label>
                        <select  onChange={incidentChanged} name='incident_linked_event'>
                            <option selected value="None"> -- create new one -- </option>
                            {raceIncidents.map((option)=>{
                                return Parser(option)
                            })}
                        </select>
                    </div>
                    {newIncident?(new_incident_html):("")}
                    <div className='form-group'>
                        <span className='message'>{message}</span><input type='submit' value='Save'></input>
                    </div> 
                </form>
            </div>
        )
    }
    else{
        return (
            <div>
                <h1>Report incident</h1>
                <p>Loading...</p>
            </div>
        )
    }
}