import axiosInstance from './axios';

export const uploadFileToServer = (file_) =>{
    return new Promise((resolve, reject)=>{
        let formData = new FormData();		
        formData.append('file', file_)
        axiosInstance.post('/upload_file', formData, {
			maxBodyLength   : Infinity,
			maxContentLength: Infinity,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then((resp)=>{
			console.log("axiosInstance ok");
            console.log(resp);
            resolve(resp.data.URL)
        }).catch((error)=>{
			console.log(error.config);
            resolve(null)
        })
    });
}