import axiosInstance from './axios';

export const get_teams = (season) =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/teams', {params: {'season': season}}).then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        });   
    })
}

export const get_user_team_code = () =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/user_team_code').then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        });   
    })
}

export const get_user_team = () =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/user_team').then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        });   
    })
}