import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {HashRouter} from 'react-router-dom'
import ReactDOM from 'react-dom';
import './App.scss'

import App from './App';

import Header from './layout_components/header';
import Footer from './layout_components/footer';

ReactDOM.render(
  <React.StrictMode>
      <HashRouter>
        <Header />
        <App  />
        <Footer />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

