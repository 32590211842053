import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom';
import user_manager from '../api/user';
import { Navbar, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import logo from '../logo.svg';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'; // for the admin
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'; // for the uci commissaire
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike'; //for the staff
import CancelIcon from '@material-ui/icons/Cancel';
import { get_user_team_code } from '../api/teams';
import { hasExactPrivilege } from '../util/auth';

export default function Header() {
    var [isLoggedIn, setLoggendIn] = useState(null);
    var [userRole, setUserRole] = useState(null);
    var [userTeamCode, setUserTeamCode] = useState(null);
    var [userIcon, setUserIcon] = useState(<CancelIcon />);

    useEffect(() => {
        console.log("Checking auth");
        if (localStorage.getItem('access_token')) {
            setLoggendIn(true);
            var role = localStorage.getItem('role');
            switch (role) {
                case 'admin':
                    setUserIcon(<VerifiedUserIcon />)
                    setUserRole("Administrator")
                    break
                case 'commissaire':
                    setUserIcon(<EmojiPeopleIcon />)
                    setUserRole("UCI commissaire")
                    break
                case 'staff':
                    setUserIcon(<DirectionsBikeIcon />)
                    setUserRole("Staff (riders/teams)")
                    get_user_team_code().then(response=>{setUserTeamCode(response.team_code)});
                    break
                default:
                    setUserIcon(<CancelIcon/>)
                    setUserRole("Logged in user")
            }
        }
        else {
            setLoggendIn(false);
        }
    }, []);

    const log_out = (evt) => {
        evt.preventDefault();
        user_manager.logout().then((data) => {
            if (data) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('username');
                localStorage.removeItem('role')
                setLoggendIn(false);
                window.location.reload();
            }
        });
    };

    return (
        <header>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                <Navbar.Brand>
                    <img
                        src={logo}
                        width="auto"
                        height="30"
                        className="d-inline-block align-top"
                        alt=""
                    />{' '}
                    UCI incident database
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {isLoggedIn ? (<li className='nav-item'><NavLink className='nav-link' to='/dashboard'>Dashboard</NavLink></li>) : <li className='nav-item'><NavLink className='nav-link' to="/">Home</NavLink></li>}
                        {isLoggedIn ? (<li className='nav-item'><NavLink className='nav-link' to="/incidents">Incidents</NavLink></li>) : (null)}
                        {isLoggedIn ? (<li className='nav-item'><NavLink className='nav-link' to="/races">Races</NavLink></li>) : (null)}
                        {!isLoggedIn ? (<li className='nav-item'><NavLink className='nav-link' to="/login">Login</NavLink></li>) : (<li className='nav-item'><Link className='nav-link' to="#" onClick={log_out}>Logout</Link></li>)}
                    </Nav>
                </Navbar.Collapse>
                {isLoggedIn && hasExactPrivilege('staff')?(<NavLink className='nav-link' to="/team_dashboard">My Team</NavLink>) : (null)}
                {isLoggedIn ? (<OverlayTrigger
                    key={userRole.replace(' ', '-')}
                    placement='bottom'
                    overlay={
                        <Tooltip id={`tooltip-${userRole.replace(' ', '-')}`}>
                            {userRole}
                        </Tooltip>
                    }>
                    {userIcon}
                </OverlayTrigger>) : ""}
                {isLoggedIn && localStorage.getItem('role')==='staff'?<span className={`userTeamCode ${userTeamCode}`}>{userTeamCode}</span>:""}
            </Navbar>
        </header>
    )
}
