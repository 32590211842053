import axiosInstance from './axios';
import {format_date_short, dictToURI} from '../util/utils';

export const incidents_per_month = (uci_category, date_from, date_to) =>{
    var props={}
    if(uci_category)
        props.uci_category=uci_category
    if(date_from)
        props.start_date = format_date_short(date_from)
    if(date_to)
        props.end_date = format_date_short(date_to)
    var propsStr = dictToURI(props)
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/monthly_incidents?'+propsStr).then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        });   
    });
}

export const incidents_by_country = (uci_category, date_from, date_to) => {
    var props = {}
    if(uci_category)
        props.uci_category=uci_category
    if(date_from)
        props.start_date = format_date_short(date_from)
    if(date_to)
        props.end_date = format_date_short(date_to)
    var propsStr = dictToURI(props)
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/incidents_by_country?'+propsStr).then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        });   
    });
}

export const incidents_by_cause_description = () =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/incidents_by_cause_description').then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        })
    })
}

export const incidents_by_participants = () =>{
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/incidents_by_participants').then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        })
    })
}

export const incidents_km_from_finish = (bin_size) =>{
    var props = {}
    if(bin_size)
        props.bin_size=bin_size
    var propsStr = dictToURI(props)
    return new Promise((resolve, reject)=>{
        axiosInstance.get('/incidents_km_from_finish?'+propsStr).then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        })
    })
}