import MaterialTable from 'material-table';
import React, { useState, useEffect } from 'react'

import getCountryISO2 from 'country-iso-3-to-2';
import ReactCountryFlag from "react-country-flag"

export default function RiderTeam(props){
    var riders = props.rider;
    var team = props.team;
    var year = props.year;
    let columns = [
        { title: 'Nat.', cellStyle: {width: 20, maxWidth: 20}, render: rowData => rowData.country.country_id?<ReactCountryFlag countryCode={getCountryISO2(rowData.country.country_iso)}/>:"", customFilterAndSearch: (term, rowData) =>
                    rowData.country.country_id?rowData.country.country_iso.toLowerCase().indexOf(term.toLowerCase()) > -1:false},
        { title: 'Last name', field: 'last_name'},
        { title: 'First name', field: 'first_name'},
        { title: 'UCI id', field: 'uci_id'},
        { title: 'rider_id', field: 'rider_id', hidden: true},
        { title: 'Birthdate', render: rowData => new Date(rowData.birthdate).toLocaleDateString(), defaultSort: 'desc', customSort: (a,b) => Date.parse(a.birthdate)-Date.parse(b.birthdate),
                customFilterAndSearch: (term, rowData) => rowData.birthdate.indexOf(term) > -1},
        {title: '# incidents', field: 'involved_in_n_incidents'}
    ];

    const selectRider = (evt, rowData)=>{
        props.onClick(rowData.rider_id);
    }

    return (
        <MaterialTable
                title={`Riders of ${year}`}
                columns={columns}
                data={riders}
                onRowClick={selectRider}
                options={{filtering: true, sorting: true, tableLayout: 'auto', showTitle:false, search: false, toolbar: false, paging: false}} 
        />
    )
}