import axiosInstance from './axios';

export const get_riders = (season, team_code) =>{
    return new Promise((resolve, reject)=>{
        var params = {}
        if(season!==null)
            params['season']=season
        if(team_code!==null)
            params['team_code']=team_code
        axiosInstance.get('/riders', {params: params}).then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        });   
    })
}

export const get_rider = (riderId) => {
    return new Promise((resolve, reject)=>{
        var params = {}
        params['rider_id']=riderId
        axiosInstance.get('/get_rider', {params: params}).then((response)=>{
            resolve(response.data);
        }).catch(()=>{
            resolve(null);
        });   
    })
}