import React from 'react'

import { Button, Modal, Spinner } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { uploadFileToServer } from '../api/files';
import { incidentMediaCategories } from "../util/values";

let allowedTypes = ["gpx+xml","JPG","PNG","PDF","MOV","AVI","MP4","WMV","MKV"]

export default function AddIncidentMediaPopup(props){
	
	const [isUploading, setIsUploading] = React.useState(false);

    const uploadFile = (file_) => {
		setIsUploading(true);
        uploadFileToServer(file_).then(url=>{
            props.setUploadedUrl(url);
			setIsUploading(false);
        })
    }

    return (
        <div>
            <Modal show={props.visible} onHide={props.hidePopup}>
                <Modal.Header closeButton>
                <Modal.Title>Add a new media to the incident</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label for="mediaType">Media type</label>
                        <select name='mediaType' id='mediaType'
                                    value={props.selectedType} 
                                    onChange={props.changeSelectedType}
                                    className='custom-select'>
                        {incidentMediaCategories.map((value, index)=>{
                            return value;
                        })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="mediaType">Media url</label>
                        <input type='text' className='form-control'
                                name='incidentMediaUrl' id='incidentMediaUrl'
                                value={props.selectedIncidentMediaUrl}
                                onChange={props.changeSelectedIncidentMediaUrl}/>
                    </div>
                    <div className="form-group">
                        { isUploading
                            ? <Spinner animation="border" variant="primary" />
                            : <>
                                <label>Or upload a new file</label>
                                <FileUploader multipe={false} minSize={0.01} maxSize={500} handleChange={uploadFile} name="file" allowed={allowedTypes}/>
                            </>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={props.hidePopup}>
                    Close
                </Button>
                <Button variant="success" onClick={props.addMedia}>
                    Add link
                </Button>
                </Modal.Footer>
            </Modal>    
        </div>
    )
};