import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table';
import incident_manager from '../api/incident';
import { useHistory} from 'react-router-dom';
import { get_rider } from '../api/riders';
import ReactCountryFlag from 'react-country-flag';
import getCountryISO2 from 'country-iso-3-to-2';

export default function RiderIncidents(props){
    const history = useHistory()
    var [riderIncidents, setRiderIncidents] = useState([]);
    var [riderObj, setRiderObj] = useState(null)
    
    useEffect(()=>{
        if(props.rider && props.season){
            console.log("loading rider"+props.rider+" season "+props.season);
            incident_manager.rider_incidents_season(props.rider, props.season).then(resp=>{
                setRiderIncidents(resp);
            })
            get_rider(props.rider).then(resp=>{
                setRiderObj(resp);
            })
        }
    }, [props.rider, props.season])

    var [columns, setColumns] = useState(
        [
            { title: 'Race date', render: rowData => new Date(rowData.race.start_datetime).toLocaleDateString()},
            { title: 'Race name', field: 'race.race_edition.race.name', hidden: false},
            { title: 'km from finish', field: 'incident.incident_km_from_finish'},			
            { title: 'Incident implications', field: 'incident_rider_implications'},
            { title: 'Incident injury implications', field: 'incident_rider_injury'},
			{ title: 'Sanction', field: 'incident.incident_rided_card'},
            { title: 'incident_id', field: 'incident.incident_id', hidden: true},
        ]);
    
    const loadIncident = (evt, rowData)=>{
        console.log(rowData)
        history.push({pathname:`/incident/${rowData.incident.incident_id}`, state: {teamMode: true}})
    }

    if(riderIncidents){
        return (
            <div>
                <div className='riderInfo'>
                    {riderObj?
                        <p>
                            {riderObj.country.country_iso?<ReactCountryFlag svg style={{height: '20px', width: 'auto'}} countryCode={getCountryISO2(riderObj.country.country_iso)}/>:""}
                            &nbsp;<b>{riderObj.first_name+" "+riderObj.last_name}</b>
                            &nbsp;- <b>{riderObj.gender}</b>
                            &nbsp;- <b>{new Date(riderObj.birthdate).toLocaleDateString()}</b>
                        </p>
                    :""}
                    {props.season?
                        <p><b>Season {props.season}</b></p>
                    :""}
                    
                </div>
                <div key='race-container'>     
                    <MaterialTable
                    title="Rider's incidents"
                    columns={columns}
                    data={riderIncidents}
                    onRowClick={loadIncident}
                    options={{filtering: true, sorting: true, tableLayout: 'auto', showTitle:false, search: false, toolbar: false, paging: false}} 
                    />
                </div>
            </div>
        )  
    }
    else{
        return (<div>
                    Loading incidents of rider ...
            </div>)
    }
}
