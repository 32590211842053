import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER_URL,
    timeout: 3600000,
    headers: {
        'Authorization': "Bearer " + localStorage.getItem('access_token'),
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
      const originalRequest = error.config;
      const refresh_token = localStorage.getItem('refresh_token');
      console.log("Interceptor fired")
      console.log(error.response.statusText)
      if (((error.response.status === 422 && error.response.statusText === "UNPROCESSABLE ENTITY") || (error.response.status === 401 && error.response.statusText === "UNAUTHORIZED")) && refresh_token && refresh_token !== undefined) {
          console.log("Trying to refresh the token.")
          axiosInstance.defaults.headers['Authorization'] = "Bearer " + refresh_token;
          return axiosInstance
              .post('/refresh')
              .then((response) => {
                  localStorage.setItem('access_token', response.data.access_token);
                  localStorage.setItem('refresh_token', refresh_token);

                  axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access_token;
                  originalRequest.headers['Authorization'] = "Bearer " + response.data.access_token;

                  return axiosInstance(originalRequest);
              })
              .catch(err => {
                  console.log(err)
              });
      }
      return Promise.reject(error);
  }
);

export default axiosInstance;
