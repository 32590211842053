import React, { useState, useEffect, useCallback } from 'react'
import incident_manager from '../api/incident';
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InfoIcon from '@material-ui/icons/Info';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function IncidentComments(props){
    let incidentId = props.incidentId
    const [comments, setComments] = useState([])
    const [commentText, setCommentText] = useState([])

    let loadComments = useCallback(() =>{
        incident_manager.incident_comments(incidentId).then((incidentComments)=>{
            setComments(incidentComments)
        })
    }, [incidentId])

    useEffect(()=>{
        loadComments();
    },[incidentId, loadComments])

    let postComment = () =>{
        incident_manager.post_comment(incidentId, commentText).then((success)=>{
            if(success){
                loadComments()
                setCommentText("")
            }
            else
                props.setMessage("Could not add the new comment")
        })
    }

    let resolveComment = (commentId) =>{
        incident_manager.resolve_comment(commentId).then((success)=>{
            if(success){
                loadComments()
            }
            else{
                props.setMessage("Could not resolve comment "+commentId)
            }
        })
    }

    let deleteComment = (commentId)=>{
        incident_manager.delete_comment(commentId).then((success)=>{
            if(success){
                loadComments()
            }
            else{
                props.setMessage("Could not delete comment "+commentId)
            }
        })
    }

    let keyListener = (e) =>{
        if(e.keyCode === 13){
            e.preventDefault()
            postComment()
        }
    }

    return (
        <div className='incident-comments'>
            <h3>Incident comments</h3>
            <section className="messages">
                <section>
                    {Object.values(comments).map(comment=>{
                        var messageType = comment['incident_comment_user_id']['email']===localStorage.getItem('username')?'me':'them';
                        var resolveStatus = comment['incident_comment_is_resolved']===1?"green":"gray";

                        return (
                            <div key={`msg-${comment.comment_id}`} className={`msg msg--${messageType}`}>
                                <blockquote className='msg-blockquote'>
                                    <div className='msg-body'>
                                        <div className='poster-info'>
                                            {messageType==='me'?"":
                                                    <OverlayTrigger
                                                        placement='left'
                                                        overlay={
                                                            <Tooltip>
                                                                Comment posted by {comment.incident_comment_user_id.email}
                                                            </Tooltip>
                                                        }>
                                                        <span><InfoIcon fontSize='small' style={{cursor: 'crosshair'}}/>{comment.incident_comment_user_id.email}</span>
                                                    </OverlayTrigger>
                                                }
                                        </div>

                                        <span key={`incident-message-${comment.comment_id}`}>{comment.incident_comment_text}</span>
                                    </div>
                                    <div className='msg-date'>
                                        {comment.incident_comment_datetime}
                                    </div>
                                    <div className='msg-actions'>
                                        <div className='msg-resolve-status' style={{cursor: 'pointer'}} onClick={()=>{resolveComment(comment.comment_id)}}>
                                            {comment['incident_comment_is_resolved']?
                                            <OverlayTrigger
                                                key={`overlay-resolve-info-${comment.comment_id}`}
                                                placement='bottom'
                                                overlay={
                                                    <Tooltip id={`tooltip-resolve-info-${comment.comment_id}`}>
                                                        Resolved by {comment.incident_comment_resolved_by.email} on {comment.incident_comment_resolved_timestamp}
                                                    </Tooltip>
                                                }>
                                                <CheckIcon style={{fill: resolveStatus}}/>
                                            </OverlayTrigger>:
                                            <CheckIcon style={{fill: resolveStatus}}/>}
                                        </div>
                                        <div className='msg-delete' style={{cursor: 'pointer'}} onClick={()=>{deleteComment(comment.comment_id)}}>
                                            <DeleteForeverIcon/>
                                        </div>
                                    </div> 
                                </blockquote>
                            </div>)
                    })}
                    
                </section>
            </section>
            <form className='msg-form'>
                <input className='msg-input' value={commentText} onChange={(evt)=>{const value=evt.target.value;setCommentText(value);}} onKeyDown={keyListener} type="text" placeholder="Type your comment"/>
                <div className="msg-send" style={{cursor: 'pointer'}} onClick={postComment}><SendIcon/></div>
            </form>
        </div>
    )
}