import React, { Component } from 'react'

export default class footer extends Component {
    render() {
        return (
            <footer  className='footer'>
                <p><b>IDLab, Ghent University</b></p>
            </footer>
        )
    }
}
