import { Route } from "react-router-dom";

import Login from './components/login'
import Incidents from './components/incidents'
import Incident from './components/incident'
import IncidentCreator from './components/incident_creator';
import IncidentEditor from './components/edit_incident';

import Races from './components/races';
import Race from './components/race';
import Dashboard from './components/dashboard';
import TeamDashboard from "./components/team_dashboard";

import home from './components/home';
import unauthorized from './components/unauthorized'

import {isLoggedIn, ProtectedRoute, ProtectedExactPrivilegedRoute, ProtectedPrivilegedRoute} from './util/auth';
import CreateIncident from './components/create_incident';

function App() {
  return (
    <div className="App">
          <Route exact path={`/login/`} component={Login}/>
          <ProtectedRoute exact path={`/dashboard/`} component={Dashboard} loggedIn={isLoggedIn()}/>
          <ProtectedRoute exact path={`/incidents/`} component={Incidents} loggedIn={isLoggedIn()}/>
          <ProtectedRoute exact path={`/incident/:id`} component={Incident} loggedIn={isLoggedIn()}/>
          <ProtectedRoute exact path={`/incident/:incident_id/edit`} component={IncidentEditor} loggedIn={isLoggedIn()}/>
          <ProtectedRoute exact path={`/races`} component={Races} loggedIn={isLoggedIn()}/>
          <ProtectedRoute exact path={`/race/:race_id`} component={Race} loggedIn={isLoggedIn()}/>
          <ProtectedRoute exact path={`/race/:race_id/create_incident`} component={CreateIncident} loggedIn={isLoggedIn()}/>
          <ProtectedPrivilegedRoute exact path={`/create_incident/:race_id`} component={IncidentCreator} loggedIn={isLoggedIn()} requiredPrivilege='commissaire'/>
          <ProtectedPrivilegedRoute exact path={`/races_admin`} component={Races} loggedIn={isLoggedIn()} requiredPrivilege={'admin'}/>
          <ProtectedExactPrivilegedRoute exact path={`/team_dashboard/`} component={TeamDashboard} loggedIn={isLoggedIn()} requiredPrivilege='staff'/>

          <Route exact path={`/`} component={home}/>
          <Route exact path={'/unauthorized'} component={unauthorized}/>
    </div>
  );
}

export default App;
