import axiosInstance from './axios';

const incident_manager = {
    get_incident: function(id){
        return new Promise((resolve, reject)=>{
            axiosInstance.get('/incidents', {params: {'incident_id': id}}).then((response)=>{
                resolve(response.data[id]);
            }).catch(()=>{
                resolve(null);
            });   
        })
    },
    get_incidents: function(){
        return new Promise((resolve, reject)=>{
            axiosInstance.get('/incidents_overview_with_causes').then((response) =>{
                resolve(response.data);
            });
        })
    },
    get_team_incidents: function(){
        return new Promise((resolve, reject)=>{
            axiosInstance.get('/team_incidents_overview_with_causes').then((response) =>{
                resolve(response.data);
            });
        })
    },
    create_incident: function(obj){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/create_incident', obj).then((response)=>{
                if(response.data.TYPE==="success"){
                    resolve(response.data.INSERTED_ID);
                }
                else{
                    reject("Not successfully created.")
                }
            }).catch((err)=>{
                reject(err);
            });
        })
    },
    create_incident_rider: function(obj){
        console.log(obj)
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/add_rider_incident', obj).then((response)=>{
                if(response.data.TYPE==="success"){
                    resolve(response.data.MESSAGE);
                }
                else{
                    reject("Not successfully created.")
                }
            }).catch((err)=>{
                console.log(err);
            });
        })
    },
    remove_incident: function(obj){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/delete_incident', obj).then((response)=>{
                if(response.data.TYPE==="success"){
                    resolve(response.data.MESSAGE);
                }
                else{
                    reject("Not successfully removed.")
                }
            }).catch((err)=>{
                console.log(err);
            });
        })
    },
    remove_rider_from_incident: function(obj){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/remove_rider_incident', obj).then((response)=>{
                if(response.data.TYPE==="success"){
                    resolve(response.data.MESSAGE);
                }
                else{
                    reject("Not successfully removed.")
                }
            }).catch((err)=>{
                reject(err);
                console.log(err);
            });
        });
    },
    last_edited_incidents: function(obj){
        return new Promise((resolve, reject)=>{
            axiosInstance.get('/last_edited_incidents?last_n=10').then((response)=>{
                resolve(response.data)
            })
        })
    },
    incident_comments: function(id){
        return new Promise((resolve, reject)=>{
            axiosInstance.get('/incident_comments?incident_id='+id).then((response)=>{
                if(id in response.data)
                    resolve(response.data[id])
                else
                    resolve({})
            })
        })
    },
    post_comment: function(incidentId, message){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/post_comment/'+incidentId, {'incident_comment_text':message}).then((response)=>{
                if(response.data['TYPE']==='SUCCESS')
                    resolve(true)
                else
                    resolve(false)
            }).catch(error=>{
                console.log("Posting the comment went wrong "+error)
                resolve(false)
            })
        })
    },
    resolve_comment: function(commentId){
        return new Promise((resolve, reject)=>{
            axiosInstance.get('/resolve_comment/'+commentId).then((response)=>{
                if(response.data['TYPE']==='SUCCESS')
                    resolve(true)
                else
                    resolve(false)
            }).catch(error=>{
                console.log("Resolving the comment went wrong "+error)
                resolve(false)
            })
        })
    },
    delete_comment: function(commentId){
        return new Promise((resolve, reject)=>{
            axiosInstance.delete('/delete_comment/'+commentId).then((response)=>{
                if(response.data['TYPE']==='SUCCESS')
                    resolve(true)
                else
                    resolve(false)
            }).catch(error=>{
                console.log("Deleting the comment went wrong "+error)
                resolve(false)
            })
        })
    },
    incident_media: function(incidentId){
        return new Promise((resolve, reject)=>{
            axiosInstance.get('/incident_media?incident_id='+incidentId).then((response)=>{
                resolve(response.data)
            }).catch(error=>{
                resolve([])
            })
        })
    },
    add_incident_media: function(incidentId, incidentMediaCategory, incidentMediaUrl){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/create_incident_media', 
                {
                    "incident_id": incidentId,
                    "incident_media_category": incidentMediaCategory,
                    "incident_media_type": "IncidentDBForm",
                    "incident_media_url": incidentMediaUrl
                }
            ).then((response)=>{
                resolve(response.data['INSERTED_ID'])
            }).catch(error=>{
                resolve(null);
            })
        });
    },
    remove_incident_media: function(incidentMediaId){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/delete_incident_media', {incident_media_id: incidentMediaId}).then((response)=>{
                resolve(response.data);
            }).catch(error=>{
                resolve(null);
            }) 
        });
    },
    update_incident_rider_implications: function(incidentId, riderId, implications){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_rider_incident_implications', {
                'incident_id': incidentId,
                'rider_id': riderId,
                'incident_rider_implications': implications
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
    update_incident_rider_injury: function(incidentId, riderId, injury){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_rider_incident_injury', {
                'incident_id': incidentId,
                'rider_id': riderId,
                'incident_rider_injury': injury
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
	update_incident_rider_card: function(incidentId, riderId, card){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_rider_incident_card', {
                'incident_id': incidentId,
                'rider_id': riderId,
                'incident_rider_card': card
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
    update_incident_description: function(incidentId, incidentDescription){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_incident_description', {
                'incident_id': incidentId,
                'incident_description': incidentDescription
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
	update_incident_type: function(incidentId, incidentType){
		if(incidentType == ''){
			incidentType = null
		}
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_incident_type', {
                'incident_id': incidentId,
                'incident_type': incidentType
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
	update_incident_summary: function(incidentId, incidentSummary){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_incident_summary', {
                'incident_id': incidentId,
                'incident_summary': incidentSummary
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
	update_incident_SAFERcomment: function(incidentId, incidentSAFERcomment){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_incident_SAFERcomment', {
                'incident_id': incidentId,
                'incident_SAFERcomment': incidentSAFERcomment
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
	update_incident_SAFERaction: function(incidentId, incidentSAFERaction){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_incident_SAFERaction', {
                'incident_id': incidentId,
                'incident_SAFERaction': incidentSAFERaction
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
    update_incident_severity: function(incidentId, incidentSeverity){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_incident_severity', {
                'incident_id': incidentId,
                'incident_severity': incidentSeverity
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
	update_incident_number_of_riders: function(incidentId, incidentNumberRiders){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_incident_number_of_riders', {
                'incident_id': incidentId,
                'incident_number_of_riders': incidentNumberRiders
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },	
	update_incident_location_latitude: function(incidentId, incidentLocationLatitude){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_incident_location_latitude', {
                'incident_id': incidentId,
                'incident_location_latitude': incidentLocationLatitude
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
	update_incident_location_longitude: function(incidentId, incidentLocationLongitude){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_incident_location_longitude', {
                'incident_id': incidentId,
                'incident_location_longitude': incidentLocationLongitude
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
    update_incident_remaining_distance: function(incidentId, incidentRemainingDistance){
        return new Promise((resolve, reject)=>{
            axiosInstance.post('/update_incident_distance', {
                'incident_id': incidentId,
                'incident_km_from_finish': incidentRemainingDistance
            }).then((resp)=>{
                resolve(true);
            }).catch(err=>{
                resolve(null);
            })
        })
    },
    rider_incidents_season: function(riderId, season){
        return new Promise((resolve, reject)=>{
            axiosInstance.get('/rider_incidents', {
                params: {
                    'rider_id':riderId, 
                    'season': season
                }
            }).then((response)=>{
                resolve(response.data)
            }).catch(err=>{
                resolve([])
            })
        });
    }
}

export default incident_manager;