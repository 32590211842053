import React, { useState, useEffect } from 'react'
import {get_last_n_days_races} from '../api/race';
import {incidents_per_month, incidents_by_country, incidents_by_cause_description, incidents_km_from_finish, incidents_by_participants} from '../api/statistics';
import incident_manager from '../api/incident'
import {month_to_name, shortenLabel} from '../util/utils';

import { useHistory} from 'react-router-dom';
import MaterialTable from 'material-table';
import {VerticalBarSeries, 
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    ChartLabel,
    FlexibleWidthXYPlot,
    Hint} from 'react-vis'

export default function Dashboard(){
    const history = useHistory()
    const lastDays = 7;

    var [lastDaysRaces, setLastDaysRaces] = useState([]);
    var [recentlyEditedIncidents, setRecentlyEditedIncidents] = useState([])
    var [incidentsMonthly, setIncidentsMonthly] = useState([]);
    var [incidentsCountry, setIncidentsCountry] = useState([])
    var [incidentsCause, setIncidentsCause] = useState([])
    var [incidentsParticipants, setIncidentsParticipants] = useState([])
    var [incidentsByDistance, setIncidentsByDistance] = useState([])
    var [causeTooltip, setCauseTooltip] = useState(null)
    var [kmTooltip, setKmTooltip] = useState(null)
    
    const tableColumns = [
        { title: 'Date', field: 'start_datetime', defaultSort: 'asc'},
        { title: 'Race', field: 'race_edition.race.name'},
        { title: 'Country', field: 'race_edition.race.country.country_iso'},
        { title: 'Cat.', field: 'race_edition.uci_class'},
    ]

    const recentlyEditedColumns = [
        { title: 'Incident date', field: 'incident_timestamp'},
        { title: 'Race', field: 'race_name'},
        { title: '# involved riders', field: 'involved_riders'},
        { title: '# causes', field: 'incident_causes'},
        { title: 'Edit date', field: 'incident_change_timestamp'},
    ]

    useEffect(()=>{
        get_last_n_days_races(lastDays).then((data)=>{
            setLastDaysRaces(data);
        });
        incident_manager.last_edited_incidents().then((data)=>{
            setRecentlyEditedIncidents(data)
        })
        incidents_per_month(null, null, null).then((data)=>{
            var options = []
            data.forEach((month)=>{
                options.push({
                    x: month_to_name(month.incident_month),
                    y: month.number_of_incidents/month.number_of_races
                })

            })
            setIncidentsMonthly(options)
        })
        incidents_by_country(null, null, null).then((data)=>{
            var options = []
            data.forEach((month)=>{
                options.push({
                    x: month.country_iso_code,
                    y: month.number_of_incidents
                })

            })
            setIncidentsCountry(options)
        })
        incidents_by_participants().then((data)=>{
            var options = []
            data.forEach((incident_stat)=>{
                options.push({
                    x: incident_stat.number_of_involved_riders,
                    y: incident_stat.number_of_incidents
                })
            })
            setIncidentsParticipants(options)
        })
        incidents_by_cause_description().then((data)=>{
            var options = []
            data.forEach((incident_stat)=>{
                options.push({
                    x: shortenLabel(incident_stat.cause_description),
                    y: incident_stat.times_reported,
                    label: incident_stat.cause_description
                })
            })
            setIncidentsCause(options)
        })
        incidents_km_from_finish(20).then((data)=>{
            var options = []
            data.forEach((incident_stat)=>{
                options.push({
                    x: incident_stat.start_km_from_finish,
                    y: incident_stat.incidents,
                    label: "km "+incident_stat.start_km_from_finish+"-->"+incident_stat.end_km_from_finish
                })
            })
            setIncidentsByDistance(options)
        })
    }, []);

    const loadRace = (evt, rowData)=>{
        //window.location.href=`${process.env.PUBLIC_URL}/#/race/${rowData.race_stage_id}`
        history.push(`/race/${rowData.race_stage_id}`)
    }

    const loadIncident = (evt, rowData)=>{
        history.push(`/incident/${rowData.incident_id}`)
    }

    return (
            <div>
                <h1>Dashboard</h1>
                <div className='row'>
                    <div className='col col-md-6'>
                        <h4>Past races (last {lastDays} days)</h4>
                        <MaterialTable
                        title="Past races"
                        columns={tableColumns}
                        data={lastDaysRaces}
                        onRowClick={loadRace}
                        options={{filtering: false, pagination: false, sorting: true}} 
                        />
                        <h4>Recently edited incidents</h4>
                        <MaterialTable
                        title="Recently edited incidents"
                        columns={recentlyEditedColumns}
                        data={recentlyEditedIncidents}
                        onRowClick={loadIncident}
                        />
                    </div>
                    <div className='col col-md-6'>
                        <h4>Incident statistics</h4>
                        <div className='row'>
                            <div className='col col-xl-6'>
                                <h5>Ratio of incidents/races per month</h5>
                                <FlexibleWidthXYPlot title="Number of incidents per month" xType="ordinal" height={400} margin={{left: 75, bottom: 75}} >
                                <VerticalGridLines/>
                                <HorizontalGridLines />
                                <XAxis tickLabelAngle={-70}/>
                                <YAxis title='Ratio incidents/races'/>
                                <ChartLabel text="month" className='alt-x-label' includeMargin={false} xPercent={0.4} yPercent={1.23}/>
                                <VerticalBarSeries data={incidentsMonthly}/>
                                </FlexibleWidthXYPlot>
                            </div>
                            <div className='col col-xl-6'>
                                <h5>Number of incidents by km from finishline</h5>
                                <FlexibleWidthXYPlot title="Number of incidents per month" xType="ordinal" height={400} margin={{left: 75, bottom: 75}} >
                                {kmTooltip && <Hint value={kmTooltip} style={{padding: '5px', borderRadius: '3px', background: 'rgba(0,0,0,0.75)', color: 'white'}}/>}
                                <VerticalGridLines/>
                                <HorizontalGridLines />
                                <XAxis tickLabelAngle={-70}/>
                                <YAxis title="Number of incidents"/>
                                <VerticalBarSeries data={incidentsByDistance} onValueMouseOver={(point)=>{
                                    setKmTooltip(point)
                                }}
                                onValueMouseOut={()=>{
                                    setKmTooltip(null)
                                }}/>
                                <ChartLabel text="Distance from finishline (km)" className='alt-x-label' includeMargin={false} xPercent={0.25} yPercent={1.23}/>
                                </FlexibleWidthXYPlot>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col col-xl-6'>
                                <h5>Number of incidents with x participants involved</h5>
                                <FlexibleWidthXYPlot title="Number of incidents per month" xType="ordinal" height={400} margin={{left: 75, bottom: 75}} >
                                <VerticalGridLines/>
                                <HorizontalGridLines />
                                <XAxis/>
                                <YAxis title='Number of incidents'/>
                                <VerticalBarSeries data={incidentsParticipants}/>
                                <ChartLabel text="involved participants" className='alt-x-label' includeMargin={false} xPercent={0.25} yPercent={1.20}/>
                                </FlexibleWidthXYPlot>
                            </div>
                            <div className='col col-xl-6'>
                                <h5>Number of incidents by cause description</h5>
                                <FlexibleWidthXYPlot title="Number of incidents per month" xType="ordinal" height={400} margin={{left: 75, bottom: 75}} >
                                {causeTooltip && <Hint value={causeTooltip} style={{padding: '5px', borderRadius: '3px', background: 'rgba(0,0,0,0.75)', color: 'white'}}/>}
                                <VerticalGridLines/>
                                <HorizontalGridLines />
                                <XAxis tickLabelAngle={-70}/>
                                <YAxis title="Number of incidents"/>
                                <VerticalBarSeries data={incidentsCause} onValueMouseOver={(point)=>{
                                    setCauseTooltip(point)
                                }}
                                onValueMouseOut={()=>{
                                    setCauseTooltip(null)
                                }}/>
                                <ChartLabel text="cause abbreviation" className='alt-x-label' includeMargin={false} xPercent={0.25} yPercent={1.23}/>
                                </FlexibleWidthXYPlot>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
    )
}
