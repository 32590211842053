import {format_date} from './utils'

export const riderToEditableList = (riders)=>{
    var output = []
    riders.forEach((rider)=>{
        console.log(rider);
        output.push({
            rider_name: rider.rider.first_name + " " + rider.rider.last_name,
            rider_team: rider.rider.resolved_team?rider.rider.resolved_team.team_name+" ("+rider.rider.resolved_team.team_code+")":"unknown",
            rider_id: rider.rider.rider_id,
            incident_rider_notes: rider.incident_rider_notes,
            incident_rider_implications: rider.incident_rider_implications,
            incident_rider_severity: rider.incident_rider_severity,
			incident_rider_card: rider.incident_rider_card,
            incident_rider_injury: rider.incident_rider_injury
        })
    })
    return output
}

export const cleanUpNullValues = (object) =>{
    var object_cpy = {...object};

    for(var key in object){
        if(object[key]===null)
            delete object_cpy[key]
        else if(object[key] === Object)
            object_cpy[key] = cleanUpNullValues(object[key])
        else if(Array.isArray(object[key]))
            object_cpy[key] = object[key].map((value)=>{return cleanUpNullValues(value)})
        else if(object[key] instanceof Date)
            object_cpy[key] = format_date(object[key])
    }
    return object_cpy

}