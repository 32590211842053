import React, { Component } from 'react'
import TrafficIcon from '@material-ui/icons/Traffic';

export default class unauthorized extends Component {
    render() {
        return (
            <div>
                <h1>Uh oh ... something went wrong!</h1>
                <p style={{textAlign: 'center'}}><TrafficIcon fontSize='large'/></p>
                <h4 style={{textAlign: 'center'}}>It seems like you don't have the required access rights to view this resource.</h4>
            </div>
        )
    }
}
