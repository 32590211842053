import { Link } from 'react-router-dom';

import incident_manager from '../api/incident';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { hasPrivileges } from '../util/auth';

export default function RaceIncident(props){
    let raceIncident = props.incident;
    let setMessage = props.setMessage;

    var confirmDialog = incident_id => {
        confirmAlert({
          title: 'Confirm to submit',
          message: 'Are you sure you want to delete the incident '+incident_id+"?",
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                  incident_manager.remove_incident({'incident_id': incident_id}).then((res)=>{
                      setMessage("Successfully removed incident "+incident_id);
                  })
              }
            },
            {
              label: 'No',
              onClick: () =>{
                  setMessage("Not deleted");
              }
            }
          ]
        });
    };

    return (
        <tr>
            <td>{raceIncident.cause_descriptions.length>0?raceIncident.cause_descriptions.join(", "):"no causes added yet"}</td>
            <td>{raceIncident.riders.length>0?raceIncident.riders.join(", "):"no riders added yet"}</td>
            <td>{raceIncident.incident_km_from_finish?raceIncident.incident_km_from_finish+"km":"remaining distance unknown"}</td>
            <td>{raceIncident.incident_timestamp}</td>
            <td>{raceIncident.incident_change_timestamp} <i>{raceIncident.incident_times_changed} time(s) changed</i></td>
            <td>{raceIncident.incident_source}</td>
            {hasPrivileges("commissaire")?<td><Link className='btn btn-warning' style={{marginRight: 3}} to={`/incident/${raceIncident.incident_id}/edit`}>Edit</Link>
            <span className='btn btn-danger' onClick={()=>{confirmDialog(raceIncident.incident_id)}}>Delete</span></td>:""}
        </tr>
    )
}