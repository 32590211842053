export const stage_index = number =>{
    if(number===0){
        return 'Oneday'
    }
    else if(number>1){
        return 'Stage '+(number-1);
    }
    else{
        return 'Stage '+number;
    }
}

export const format_date = date_obj =>{
    var year = date_obj.getFullYear()
    var month = date_obj.getMonth()+1
    var day = date_obj.getDate();

    var hours = date_obj.getUTCHours()
    var minutes = date_obj.getUTCMinutes()
    var seconds = date_obj.getUTCSeconds()

    return `${year}-${zeroPad(month,2)}-${zeroPad(day,2)} ${zeroPad(hours,2)}:${zeroPad(minutes,2)}:${zeroPad(seconds, 2)}`
}

export const format_date_short = date_obj =>{
    var year = date_obj.getFullYear()
    var month = date_obj.getMonth()+1
    var day = date_obj.getDate();

    return `${year}-${zeroPad(month,2)}-${zeroPad(day,2)}`
}

export const dictToURI = (dict)=>{
    var str = [];
    for(var p in dict){
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(dict[p]));
    }
    return str.join("&");
}

export const month_to_name = (month_num) =>{
    switch(month_num){
        case 1:
            return "Jan"
        case 2:
            return "Feb"
        case 3:
            return "Mar"
        case 4:
            return "Apr"
        case 5:
            return "May"
        case 6:
            return "Jun"
        case 7:
            return "Jul"
        case 8: 
            return "Aug"
        case 9:
            return "Sep"
        case 10:
            return "Oct"
        case 11:
            return "Nov"
        case 12:
            return "Dec"
        default:
            return "Unk"
    }
}
const zeroPad = (num, places) => String(num).padStart(places, '0')

export const shortenLabel = (label)=>{
    //Check if the label consists of multiple words
    var parts = label.split(" ")
    var abbrev = ""
    if(parts.length>1){
        //Get the first letter of each part
        parts.map((part)=>{
            var loc = 0
            while(loc < part.length && [',', ')','('].includes(part.charAt(loc))){
                loc+=1
            }
            abbrev+=part.charAt(loc).toUpperCase()
            return null
        })
        if(abbrev.length>3){
            abbrev = abbrev.substring(0,3)
        }
    }
    else{
        //Get the first three chars of the sentence
        abbrev+=label.substring(0,3)
    }
    return abbrev
}

export const simpleHTMLSearch = (inputField, target) => {
    // Declare variables
    var input, filter, table, tr, tds, td, i, txtValue;
    input = document.getElementById(inputField);
    filter = input.value.toUpperCase();
    table = document.getElementById(target)
    tr = table.getElementsByTagName("tr");
      
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 1; i < tr.length; i++) {
        tds = tr[i].getElementsByTagName("td");
        let found = false
        for(let j=0; j<tds.length; j++){
            td = tds[j]
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                found=true
                break
            }
        }
        tr[i].style.display = found?"":"none";
    }
}